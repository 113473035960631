<template>
  <v-container>
    <v-card class="px-5 py-3" v-if="object_type != 'is_leisure'">
      <v-tabs class="mb-3">
        <v-tab @click="loadRow(1)">Активные</v-tab>
        <v-tab @click="loadRow(0)">Не активные</v-tab>
      </v-tabs>
      <v-data-table
        v-model="selected"
        key="hello"
        :headers="headers"
        :items="items"
        :single-select="true"
        :items-per-page="10"
        :loading="categoryLoading"
        item-key="id"
        sort-by="category"
        :footer-props="footerProps"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="deleted" small class="mr-2" @click="edit(item)">
            mdi-pencil
          </v-icon>
          <v-icon v-if="deleted" color="red" small @click="remove(item)">
            mdi-delete
          </v-icon>
          <v-icon
            v-if="!deleted"
            small
            class="mr-2"
            @click="remove(item)"
            color="success"
          >
            mdi-power
          </v-icon>
        </template>
      </v-data-table>
      <v-btn color="primary" class="ma-3" @click="create">
        Добавить категорию
      </v-btn>
    </v-card>
    <v-card v-else class="px-5 py-3">
      <v-tabs class="mb-3">
        <v-tab @click="loadRow(1)">Активные</v-tab>
        <v-tab @click="loadRow(0)">Не активные</v-tab>
      </v-tabs>
      <v-data-table
        v-model="selected"
        :headers="headersLeisure"
        :items="items"
        :single-select="true"
        :items-per-page="10"
        :loading="categoryLoading"
        :options.sync="options"
        :footer-props="footerProps"
        item-key="id"
        sort-by="category"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editLeisure(item)">
            mdi-pencil
          </v-icon>
          <v-icon color="red" small @click="remove(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
      <v-btn color="primary" class="ma-3" @click="create">
        Добавить категорию
      </v-btn>
    </v-card>
    <v-dialog v-model="dialog" width="400">
      <v-card style="padding: 30px 0px">
        <h3 style="text-align: center" v-if="deleted">
          Вы уверены что хотите удалить категорию? <br />
          В ней могут находиться брони
        </h3>
        <h3 style="text-align: center" v-else>
          Вы хотите восстановить категорию?
        </h3>
        <div
          class="mt-4"
          style="display: flex; align-items: center; justify-content: center"
        >
          <v-btn
            @click="deleteCategory"
            :loading="deleteLoading"
            color="primary"
            ><span v-if="deleted">Удалить</span
            ><span v-else>Восстановить</span></v-btn
          >
          <v-btn @click="dialog = false">Отмена</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="leisureDialog"
      :retain-focus="true"
      eager
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">
          {{ is_edit ? "Изменение категории" : "Добавление категории" }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" class="pa-2">
              <v-text-field
                :rules="[v => !!v]"
                v-model="leisureModel.name"
                label="Введите название"
                dense
              />
            </v-col>
            <v-col cols="12" md="6" class="pa-2">
              <v-text-field
                :rules="[v => !!v]"
                v-model="leisureModel.slug"
                label="Введите slug"
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" class="pa-2">
              <v-text-field
                v-model="leisureModel.stock_number"
                type="number"
                label="Количество"
                @keydown="preventSigns"
                min="1"
                dense
                :rules="[v => !!v]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-2">
              <v-text-field
                v-model="leisureModel.address"
                label="Введите адрес"
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" class="pa-2">
              <v-select
                :items="leisuretypeList"
                v-model="leisureModel.leisure_type"
                item-text="name"
                dense
                item-value="id"
                label="Тип отдыха"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="pa-0 pl-1"
              style="vertical-align:top"
            >
              <v-checkbox
                v-model="leisureModel.is_active"
                label="Активный"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <span>Описание header</span>
          <div ref="editorWrapperDescr">
            <editor
              apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
              v-model="leisureModel.header_description"
              :init="init"
            />
          </div>

          <span>Описание</span>
          <div ref="editorWrapperDescr1">
            <editor
              apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
              v-model="leisureModel.description"
              :init="init"
            />
          </div>
        </v-card-text>
        <v-row class="mx-2">
          <v-col class="pt-0 pb-0" cols="4">
            <v-file-input
              v-model="singleImage"
              label="Главное фото"
              prepend-icon="mdi-camera"
              show-size
              chips
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="8">
            <v-file-input
              ref="multiImages"
              v-model="multiImages"
              label="Фото"
              prepend-icon="mdi-camera"
              multiple
              counter
              show-size
              chips
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-col class="pt-0 pb-0 ml-5" cols="12">
          <v-row class="mb-5" align="center" v-if="leisureModel.main_image">
            <img :src="leisureModel.main_image" height="300" />
            <v-btn icon @click="deleteMainImage">
              <v-icon small color="red darken-2"> mdi-delete </v-icon>
            </v-btn>
          </v-row>

          <div v-for="(image, index) in leisureModel.image_list" :key="index">
            <v-row class="mb-5" align="center" v-if="image.is_main">
              <img :src="image.image" height="300" />
              <v-btn icon @click="deleteImage(index, image.id)">
                <v-icon small color="red darken-2"> mdi-delete </v-icon>
              </v-btn>
            </v-row>
            <v-row class="mb-4" align="center" v-if="!image.is_main">
              <img :src="image.image" height="200" />
              <v-btn icon @click="deleteImage(index, image.id)">
                <v-icon small color="red darken-2"> mdi-delete </v-icon>
              </v-btn>
            </v-row>
          </div>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              (leisureDialog = false),
                (leisureModel = {}),
                (singleImage = null),
                (multiImages = [])
            "
          >
            Назад
          </v-btn>
          <v-btn
            @click="saveLeisure"
            color="primary"
            :loading="btnLoading"
            :disabled="btnLoading"
            >сохранить</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import toast from "../../../plugins/toast";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js";
import { LeisureCategoriesApiUrls } from "@/services/leisureRequests/categories.api.js";
import { LeisureApiUrls } from "@/services/leisureRequests/leisure.api.js";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "Tariff",
  components: {
    editor: Editor
  },
  data: () => ({
    init: {
      height: 300,
      menubar: false,
      menu: {
        edit: { title: "Edit", items: "undo redo | selectall" }
      },
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount"
      ],
      toolbar:
        "undo redo | fontselect | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help"
    },
    disabledBtn: false,
    leisuretypeList: [],
    leisureModel: {},
    singleImage: null,
    multiImages: [],
    categoryLoading: false,
    tmpItem: {},
    deleteLoading: false,
    dialog: false,
    e1: 1,
    deleted: true,
    singleSelect: false,
    selected: [],
    headersLeisure: [
      {
        text: "Название",
        align: "start",
        sortable: false,
        value: "name",
        class: "table-title"
      },
      {
        text: "Количество",
        align: "start",
        sortable: false,
        value: "stock_number",
        class: "table-title"
      },
      {
        text: "Действия",
        value: "actions",
        sortable: false,
        class: "table-title"
      }
    ],
    headers: [
      {
        text: "Название",
        align: "start",
        sortable: false,
        value: "name",
        class: "table-title"
      },
      { text: "Основные номера", value: "count", class: "table-title" },
      {
        text: "Доп. места",
        value: "additional_count",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Действия",
        value: "actions",
        sortable: false,
        class: "table-title"
      }
    ],
    items: [],
    is_edit: false,
    object_type: localStorage.getItem("object_type"),
    leisureDialog: false,
    btnLoading: false,
    footerProps: {
      "items-per-page-options": [
        {
          text: "10",
          value: 10
        },
        {
          text: "20",
          value: 20
        },
        {
          text: "30",
          value: 30
        },
        {
          text: "Все",
          value: 99999999999
        }
      ]
    },
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    firstWatcherTrigger: false
  }),
  watch: {
    options: {
      async handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        console.log(123);
        await this.loadRow();
      },
      deep: true
    },
    async leisureDialog() {
      if (!this.leisureDialog) {
        await this.loadRow();
        this.is_edit = false;
      }
    }
  },
  async created() {
    await this.loadRow();
    this.$forceUpdate();
  },
  beforeDestroy() {
    location.reload();
  },
  methods: {
    deleteMainImage() {
      this.leisureModel.main_image = null;
    },
    async deleteImage(index, idImage) {
      await LeisureCategoriesApiUrls.deleteCategoryImage(idImage);
      this.leisureModel.image_list.splice(index, 1);
    },
    async editLeisure(item) {
      let resp = await LeisureCategoriesApiUrls.getCategory(item.id);
      this.leisureModel = resp;
      this.leisureDialog = true;
      this.is_edit = true;
    },
    preventSigns(e) {
      if (e.key == "-" || e.key == "+") {
        e.preventDefault();
      }
    },
    async getLeisureTypes() {
      let params = {
        "page[number]": 1,
        "page[size]": 100000
      };
      let res = await LeisureApiUrls.getLeisureTypes(params);
      this.leisuretypeList = res.results;
    },
    async loadRow(value = null) {
      this.categoryLoading = true;
      if (this.object_type == "is_leisure") {
        if (value !== null) this.deleted = value;
        let params = {
          is_active: this.deleted ? true : false,
          deleted: !this.deleted ? true : false,
          "page[number]": this.options.page || 1,
          "page[size]": 100
        };
        this.items = await LeisureCategoriesApiUrls.getCategories(params);
        console.log(this.items, this.options)
        this.categoryLoading = false;
        await this.getLeisureTypes();
      } else {
        if (value !== null) this.deleted = value;
        this.items = [];
        let params = {
          deleted: !this.deleted ? true : false,
          "page[number]": this.options.page || 1,
          "page[size]": 100
        };
        this.initialize(await CategoriesApiUrls.getCategories(params));
        this.categoryLoading = false;
      }
    },
    initialize(dataSet) {
      dataSet.map(el => {
        const obj = {
          id: el.id,
          name: el.name,
          company_id: el.company_id,
          additional_count: el.additional_place,
          count: el.place_number
        };
        this.items.push(obj);
      });
      console.log(this.items, this.options)
    },
    async saveLeisure() {
      if (
        !this.leisureModel.name ||
        !this.leisureModel.slug ||
        !this.leisureModel.stock_number
      ) {
        toast.error("Заполните данные!");
        return;
      }
      this.btnLoading = true;
      if (this.leisureModel.id) {
        try {
          let resp = await LeisureCategoriesApiUrls.setCategory(
            this.leisureModel.id,
            { ...this.leisureModel }
          );
          if (this.singleImage) {
            var formData = new FormData();
            formData.append(
              "main_image",
              this.singleImage,
              this.singleImage.fileName
            );
            await LeisureCategoriesApiUrls.setCategoryMainImage(
              resp.id,
              formData
            );
            this.singleImage = null;
          }

          if (this.multiImages.length > 0) {
            this.multiImages.forEach(async function(e) {
              var formData = new FormData();
              formData.append("image", e, e.fileName);
              formData.append("category", resp.id);
              await LeisureCategoriesApiUrls.createCategoryImage(formData);
            });
            this.multiImages = [];
          }
        } finally {
          this.btnLoading = false;
          this.leisureDialog = false;
          this.leisureModel = {};
        }
      } else {
        try {
          let resp = await LeisureCategoriesApiUrls.createCategory({
            ...this.leisureModel
          });
          if (this.singleImage) {
            var formData = new FormData();
            formData.append(
              "main_image",
              this.singleImage,
              this.singleImage.fileName
            );
            await LeisureCategoriesApiUrls.setCategoryMainImage(
              resp.id,
              formData
            );
            this.singleImage = null;
          }

          if (this.multiImages.length > 0) {
            this.multiImages.forEach(async function(e) {
              var formData = new FormData();
              formData.append("image", e, e.fileName);
              formData.append("category", resp.id);
              await LeisureCategoriesApiUrls.createCategoryImage(formData);
            });
            this.multiImages = [];
          }
        } finally {
          this.btnLoading = false;
          this.leisureDialog = false;
          this.leisureModel = {};
        }
      }
    },
    create() {
      if (this.object_type == "is_leisure") {
        this.leisureDialog = true;
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
        this.leisureModel = {};
      } else {
        this.$router.push("/category");
      }
    },
    edit(item) {
      this.$router.push("/category/" + item.id + "/");
    },
    async deleteCategory() {
      this.deleteLoading = true;
      if (this.object_type == "is_leisure") {
        await LeisureCategoriesApiUrls.deleteCategory(this.tmpItem.id);
        if (!this.deleted) toast.success("Успешно восстановлено!");
        else toast.success("Успешно удалено!");
        this.loadRow();
        this.deleteLoading = false;
        this.dialog = false;
      } else {
        await CategoriesApiUrls.deleteCategory(this.tmpItem.id);
        if (!this.deleted) toast.success("Успешно восстановлено!");
        else toast.success("Успешно удалено!");
        this.loadRow();
        this.deleteLoading = false;
        this.dialog = false;
      }
    },
    remove(item) {
      this.tmpItem = item;
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
